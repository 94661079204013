body {
  background-color: #e2e2df;
}

.App {
  font-family: "Newsreader", serif;
  font-style: italic;
  color: #2e2e3a;
}

.App-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px;
  align-items: flex-start;
  position: relative;
}

.app_title {
  text-transform: lowercase;
  letter-spacing: 3px;
  font-size: 28px;
  margin: -55px 15px -10px 15px;
  color: #2e2e3a;
  text-decoration: none;
  line-height: 60px;
  padding-bottom: 0px;
}

.landscape-img,
.landscape-mirror-img {
  max-width: 300px;
  margin: 50px 0px 25px 15px;
}

a {
  text-decoration: none;
}

.home {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.welcome {
  font-size: 70px;
  line-height: 70px;
  color: #2e2e3a;
}

.artists-prompts {
  color: #e93c0c;
}

.home-desc {
  font-size: 30px;
  margin-top: -25px;
  color: #2e2e3a;
}

.home-img {
  max-width: 1000px;
  display: flex;
  text-align: center;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 50px;
  width: 100%;
}

/**** LOADING *****/
.notLoading {
  display: none;
}

.loading {
  margin: 30px auto 0 auto;
  text-align: center;
}

@media (min-width: 700px) {
  .loading {
    margin-top: 100px
  }
}

.enter-link {
  font-size: 38px;
  font-weight: 600;
  color: #2e2e3a;
}

.enter-link:hover {
  color: #e93c0c;
}

/*** USER NAV (PROFILE AND POST LINKS) ****/
.userNav-header {
  font-size: 24px;
  text-transform: none;
  margin-top: -10px;
}

.userNavUL {
  display: flex;
  justify-content: space-between;
  max-width: 340px;
  margin: 0 auto;
  border-bottom: 0.8px #2e2e3a solid;
  padding-inline-start: 0;
  padding-bottom: 20px;
}

.navigation-link-user {
  text-decoration: none;
  font-size: 30px;
  /* text-transform: uppercase;
  text-align: right;
  margin-right: 30px; */
}

.navigation-link-user{
  color: #333533;
}

.active-nav {
  color: #e93c0c;
}

.icon-nav:hover {
  color: #e93c0c;
}

@media (max-width: 700px) {
  .userNavUL {
    margin-top: 60px;
    max-width: 250px;
  }
  .icon-nav {
    font-size: 1.4rem;
}
}

/*** PROMPT CARD *****/
.Prompt {
  margin: 50px;
  padding: 30px;
  max-width: 500px;
  min-width: 500px;
  --borderWidth: 3px;
  background: #e2e2df;
  position: relative;
  margin-left: 0px;
}
.Prompt:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(
    60deg,
    #211103,
    #75704e,
    #e93c0c,
    #5f524a,
    #211103
  );
  z-index: -1;
  animation: animatedgradient 4s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.username {
  color: #333533;
  text-decoration: none;
  text-transform: lowercase;
  font-size: 30px;
  letter-spacing: 3px;
}

.prompt_content {
  color: #e93c0c;
  font-size: 58px;
}

.date {
  color: #331d0a;
}

.tag_list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 24px;
  align-items: flex-end;
}

.tag-link {
  text-decoration: none;
  color: #2e2e3a;
  margin-left: -35px;
}

/*** NEW PROMPT  *****/

.new-prompt-form {
  margin: 70px auto 0 auto;
  padding: 30px;
  max-width: 500px;
  border: solid 2px #2e2e3a;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;
}

.multiselect {
  min-width: 200px;
}

.new-prompt-label {
  margin: 10px;
  font-size: 40px;
}

.char-limit {
  margin-top: 5px;
  margin-bottom: -5px;
}

.prompting-as {
  font-weight: 100;
}

#new-prompt-textarea {
  width: 300px;
  height: 100px;
  font-family: "Newsreader", serif;
  font-size: 24px;
  padding: 10px;
}

.new-prompt-submit {
  margin-top: 15px;
}

/*** PROMPT LIST  *****/
li {
  list-style: none;
}

.prompt-feed-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*** LOG IN BUTTONS ****/
.nav-log {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.log_button {
  display: inline-block;
  border: none;
  text-transform: uppercase;
  font-family: "Newsreader", serif;
  font-style: italic;
  font-weight: bold;
  background-color: #e3e1de;
  font-size: 30px;
  text-align: right;
  margin-left: 25px;
  margin-top: -10px;
  padding: 10px;
}

/********* PROFILE **********/

.profile {
  margin: 25px;
  text-align: center;
}

.profile-prompt-list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/********* USER PROMPTS **********/
.prompts-by {
  text-align: center;
  font-size: 32px;
  margin: 40px 0px 0px -25px;
  font-weight: 100;
}

@media (max-width: 700) {
  .prompts-by {
    margin-top: 24px;
  }
}


/********* FOLLOW BUTTON ************/
.follow-button {
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  background-color: #2e2e3a;
  color: white;
  padding: 10px;
  border: none;
  font-size: 16px;
  font-family: "Newsreader", serif;
}

@media (max-width: 700px) {
  .follow-button {
    margin-top: 20px;
  }
}

/********** DISCOVER *****************/
.discover {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 500px;
}

.discover-label {
  font-size: 35px;
}
.discover-multiselect {
  max-width: 600px;
  min-width: 250px;
  margin: 0 auto;
}

@media (max-width: 800px) {
  .app_title {
    margin-bottom: 30px;
  }

  .Prompt {
    max-width: 400px;
    min-width: 400px;
  }

  .prompt-feed-list {
    margin-left: -30px;
  }
}

@media (max-width: 700px) {
  .home-img {
    margin-top: 30px;
  }
  .landscape-mirror-img {
    display: none;
  }

  .landscape-img {
    display: flex;
    max-width: 250px;
  }
  .App-header {
    display: flex;
  }
  .log_button {
    margin-top: 10px;
    margin-left: 0px;
    font-size: 24px;
    color: #e93c0c;
  }
}

@media (max-width: 600px) {
  .home {
    margin-top: 20px;
  }

  .Prompt {
    max-width: 300px;
    min-width: 300px;
    margin-top: 10px;
    border: solid 2px #2e2e3a;
  }

  .prompt-feed-list {
    margin-left: -35px;
  }

  .prompts-by {
    font-size: 30px;
  }
}
@media (max-width: 400px) {
  .log_button {
    margin-top: -10px;
    font-size: 24px;
  }
}

@media (max-width: 420px) {
  .home {
    margin-top: 40px;
  }

  .welcome {
    font-size: 45px;
  }
  .home-desc {
    font-size: 20px;
    margin-top: -25px;
  }
  .app_title {
    font-size: 20px;
  }

  .Prompt {
    max-width: 250px;
    min-width: 250px;
    margin-top: 15px;
    margin-left: 45px;
    padding: 12px;
  }

  .username {
    font-size: 24px;
  }

  .prompt_content {
    font-size: 38px;
  }

  .landscape-img {
    display: flex;
    max-width: 200px;
  }

  .prompt-feed-list {
    margin-left: -38px;
  }

  .userNav-header {
    margin-top: -25px;
  }

  .nav-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
  }

  textarea {
    max-width: 225px;
  }
}

@media (max-width: 290px) {
  .app_title {
    font-size: 16px;
  }
}

@media (max-width: 268px) {
  .app_title {
    font-size: 14px;
  }
}
